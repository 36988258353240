import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const LeadForm = () => {
   const [formData, setFormData] = useState({
      nomeCompleto: '',
      email: '',
      telefone: '',
      nomeEmpresa: '',
      websiteEmpresa: '',
      tipoProjeto: '',
      descricaoProjeto: '',
      orcamento: '',
      prazo: '',
      tamanhoEmpresa: '',
      industria: '',
      objetivosProjeto: '',
      plataformasDesejadas: '',
      funcionalidadesNecessarias: '',
      referenciasProjetos: '',
      permissaoContato: false,
      preferenciaContato: '',
   });

   const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({
         ...formData,
         [name]: type === 'checkbox' ? checked : value,
      });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      fetch('https://api.wfsoftware.com.br/cadastrar-leads', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(formData),
      })
         .then((response) => {
            if (!response.ok) {
               throw new Error('Erro ao enviar formulário');
            }
            return response.json(); // Interpretar a resposta como JSON
         })
         .then((data) => {
            console.log('Sucesso:', data.message);
            alert(data.message);
         })
         .catch((error) => {
            console.error('Erro ao enviar formulário:', error);
            alert('Erro ao enviar formulário');
         });
   };

   return (
      <div>
         <Navbar />
         <div className='min-h-screen  p-0 sm:p-12'>
            <div className=' flex justify-center items-center mx-auto max-w-xl px-6 py-12 bg-white border-0 shadow-lg md:mb-8 sm:rounded-3xl'>
               <form onSubmit={handleSubmit}>
                  <h1 className=' flex justify-center items-center  text-2xl font-bold text-sky-900'>
                     Solicitação de avaliação
                  </h1>
                  <div className='w-full mt-5'>
                     <div className='my-2'>
                        <label
                           htmlFor='nomeCompleto'
                           className='block mb-2 text-sm font-medium text-sky-800 md:text-lg'
                        >
                           Informe seu nome:
                        </label>
                        <input
                           type='text'
                           id='nomeCompleto'
                           name='nomeCompleto'
                           placeholder='Nome Completo'
                           value={formData.nomeCompleto}
                           onChange={handleChange}
                           required
                           className='bg-sky-50 border  text-sm md:text-lg md:p-3 rounded-lg  block w-full p-2.5 focus:border-sky-900 '
                        />
                     </div>
                     <div className='my-2'>
                        <label
                           htmlFor='email'
                           className='block mb-2 text-sm font-medium text-sky-800 md:text-lg '
                        >
                           Informe seu e-mail:
                        </label>
                        <input
                           type='email'
                           id='email'
                           name='email'
                           placeholder='E-mail'
                           value={formData.email}
                           onChange={handleChange}
                           required
                           className='bg-sky-50 border  text-sm rounded-lg  md:text-lg md:p-3 block w-full p-2.5 focus:border-sky-900 '
                        />
                     </div>
                     <div className='my-2'>
                        <label
                           htmlFor='telefone'
                           className='block mb-2 text-sm font-medium md:text-lg  text-sky-800'
                        >
                           Informe seu telefone:
                        </label>
                        <input
                           type='tel'
                           id='telefone'
                           name='telefone'
                           placeholder='Telefone'
                           value={formData.telefone}
                           onChange={handleChange}
                           required
                           className='bg-sky-50 border  text-sm rounded-lg md:text-lg md:p-3  block w-full p-2.5 focus:border-sky-900 '
                        />
                     </div>
                     <div className='my-2'>
                        <label
                           htmlFor='nomeEmpresa'
                           className='block mb-2 text-sm font-medium md:text-lg text-sky-800'
                        >
                           Informe sua empresa:
                        </label>
                        <input
                           type='text'
                           id='nomeEmpresa'
                           name='nomeEmpresa'
                           placeholder='Nome da empresa'
                           value={formData.nomeEmpresa}
                           onChange={handleChange}
                           required
                           className='bg-sky-50 border md:text-lg text-sm rounded-lg  block w-full p-2.5 focus:border-sky-900 '
                        />
                     </div>
                     <div className='my-2'>
                        <label
                           htmlFor='tipoProjeto'
                           className='block mb-2 text-sm font-medium md:text-lg  text-sky-800'
                        >
                           Informe qual o tipo de desenvolvimento busca:
                        </label>
                        <select
                           name='tipoProjeto'
                           value={formData.tipoProjeto}
                           onChange={handleChange}
                           required
                           className='bg-sky-50 border text-sky-900 md:text-lg md:p-3  text-sm rounded-lg  block w-full p-2.5 focus:border-sky-900 '
                        >
                           <option
                              value=''
                              disabled
                           >
                              Infome quais são suas prioridades
                           </option>
                           <option value='web'>Aplicação web</option>
                           <option value='mobile'>Aplicação Mobile</option>
                           <option value='outro'>Ambos</option>
                        </select>
                     </div>
                     <div className='my-2'>
                        <label
                           htmlFor='descricaoProjeto'
                           className='block mb-2 text-sm md:text-lg font-medium text-sky-800'
                        >
                           Relate brevemente o que busca:
                        </label>
                        <textarea
                           name='descricaoProjeto'
                           id='descricaoProjeto'
                           placeholder='Cite uma breve descrição do que deseja...'
                           value={formData.descricaoProjeto}
                           onChange={handleChange}
                           required
                           className='h-28 bg-sky-50 border md:text-lg  text-sky-900  text-sm rounded-lg  block w-full p-2.5 focus:border-sky-900'
                        />
                     </div>
                     <div className='my-2'>
                        <label className='flex items-center justify-center'>
                           <input
                              type='checkbox'
                              name='permissaoContato'
                              checked={formData.permissaoContato}
                              onChange={handleChange}
                              className='rounded border-sky-900'
                           />
                           <div className='text-sky-900 text-lg font-bold ml-3'>
                              Permissão para ser contatado
                           </div>
                        </label>
                     </div>
                     <div className='my-2'>
                        <label
                           htmlFor='preferenciaContato'
                           className='block mb-2 text-sm md:text-lg  font-medium text-sky-800'
                        >
                           Preferência de contato:
                        </label>
                        <select
                           name='preferenciaContato'
                           id='preferenciaContato'
                           value={formData.preferenciaContato}
                           onChange={handleChange}
                           required
                           className='bg-sky-50 border md:text-lg md:p-3 text-sky-900  text-sm rounded-lg  block w-full p-2.5 focus:border-sky-900 '
                        >
                           <option
                              value=''
                              disabled
                           >
                              Preferência de contato
                           </option>
                           <option value='telefone'>Telefone/Whatsapp</option>
                           <option value='email'>Email</option>
                        </select>
                     </div>
                     <button
                        type='submit'
                        className='btn bg-orange-300 text-2xl py-2  border-orange-600 hover:border-orange-600  hover:bg-orange-200 px-24 w-full mt-4'
                     >
                        <div className='text-orange-600 '>Enviar</div>
                     </button>
                  </div>
               </form>
            </div>
         </div>
         <Footer />
      </div>
   );
};

export default LeadForm;
