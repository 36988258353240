import { Link } from 'react-router-dom';

import reports from '../assets/images/reports.svg';

const ApresentacaoReports = () => {
   return (
      <section id='hero'>
         {/* Flex Container */}
         <div className='container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row'>
            {/* Left Item */}
            <div className='flex flex-col mb-32 space-y-1 2 md:w-1/2'>
               <h1 className='text-sky-900   max-w-md text-4xl font-bold text-center md:text-5xl md:text-left'>
                  Reports
               </h1>
               <div className='min-h-screen md:p-8'>
                  <div className='container mx-auto bg-slate-50 bg-opacity-60 p-6 rounded-lg shadow-lg'>
                     <p className='text-gray-700 mb-4'>
                        O Klin Reports é uma ferramenta inovadora de geração de
                        relatórios desenvolvida para a empresa Klin Produtos
                        Infantis LTDA. Lançado em setembro de 2023, o Klin
                        Reports foi criado com o objetivo de automatizar e
                        agilizar o processo de geração de relatórios, reduzindo
                        significativamente os custos operacionais.
                     </p>
                     <p className='text-gray-700 mb-4'>
                        Este sistema permite a criação rápida e eficiente de
                        relatórios detalhados sobre diversos aspectos das
                        operações da empresa, incluindo vendas, estoque,
                        produção e mais. Com uma interface intuitiva e fácil de
                        usar, os usuários podem gerar relatórios personalizados
                        com apenas alguns cliques, economizando tempo e
                        recursos.
                     </p>
                     <p className='text-gray-700 mb-4'>
                        Nos primeiros 8 meses após o lançamento, o Klin Reports
                        gerou mais de 100 mil relatórios, comprovando sua
                        eficácia e importância para a gestão da Klin Produtos
                        Infantis LTDA. Através desta ferramenta, a empresa
                        conseguiu melhorar a precisão dos dados, tomar decisões
                        mais informadas e otimizar seus processos internos.
                     </p>
                     <p className='text-gray-700'>
                        Em resumo, o Klin Reports é um poderoso gerador de
                        relatórios que transformou a maneira como a Klin
                        Produtos Infantis LTDA gerencia suas informações,
                        proporcionando maior agilidade, precisão e economia.
                     </p>
                     <div className='flex justify-center items-center pt-10 md:pt-4'>
                        <Link
                           to='/agendar-avaliacao'
                           className='p-3 px-6 pt-2 shadow-sm shadow-slate-400  text-white bg-orange-400 rounded-full baseline hover:bg-orange-300  '
                        >
                           Solicite uma avaliação
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
            {/* Image */}
            <div className='  sm:block hidden'>
               <img
                  src={reports}
                  alt=''
               />
            </div>
         </div>
      </section>
   );
};

export default ApresentacaoReports;
