import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PiCircuitryDuotone } from "react-icons/pi";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className='relative container mx-auto p-6'>
      {/* Flex Container */}
      <div className='flex items-center justify-between'>
        {/* Logo */}
        <Link to='/'>
        <div className='pt-2'>
          <div className='flex items-center justify-center'>
            <PiCircuitryDuotone className='text-5xl text-orange-600'/>
            <h1 className='text-3xl font-bold text-sky-900 '>WF Software</h1>
          </div>
        </div>
        </Link>
        {/* Menu Items */}
        <div className='hidden space-x-6 md:flex text-sky-900  text-2xl font-bold'>
          <Link to='/agendar-avaliacao' className='hover:text-sky-600 '>
            Orçamentos
          </Link>
          <Link to='/sobre-nos' className='hover:text-sky-600 '>
            Sobre nós
          </Link>
          <Link to='/projetos' className='hover:text-sky-600 '>
            Projetos
          </Link>
        </div>
        {/* Button */}
        <a
          href="https://reports.wfsoftware.com.br"
          className='shadow-sm shadow-slate-400 hidden p-3 px-6 pt-2 text-white bg-orange-400 rounded-full baseline hover:bg-orange-300 md:block'
        >
          Área do cliente
        </a>


        {/* Hamburger Icon */}
        <button
          className={
            toggleMenu
              ? 'open block hamburger md:hidden focus:outline-none'
              : 'block hamburger md:hidden focus:outline-none'
          }
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <span className='hamburger-top'></span>
          <span className='hamburger-middle'></span>
          <span className='hamburger-bottom'></span>
        </button>
      </div>

      {/* Mobile Menu */}
      <div className='md:hidden'>
        <div
          className={
            toggleMenu
              ? 'absolute flex flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
              : 'absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
          }
        >
          <Link to='/projetos'>Soluções</Link>
          <Link to='/sobre-nos'>Sobre nós</Link>
          <Link to='/agendar-avaliacao'>Projetos</Link>
          <a
            href="https://reports.wfsoftware.com.br"
            className="shadow-sm shadow-black p-3 px-6 pt-2 text-white bg-orange-600 rounded-full baseline hover:bg-orange-400 md:block"
          >
            Área do cliente
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;