import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ApresentacaoReports from '../components/apresentacao-reports';
import ApresentacaoPhotos from '../components/apresentacao-photos';
import ApresentacaoExportacao from '../components/apresentacao-projeto-exportacao';

const Projetos = () => {
   return (
      <div>
         <Navbar />
         <ApresentacaoReports />
         <ApresentacaoPhotos />
         <ApresentacaoExportacao />
         <Footer />
      </div>
   );
};

export default Projetos;
