import { Link } from 'react-router-dom';

import photos from '../assets/images/photos.svg';

const ApresentacaoPhotos = () => {
   return (
      <section id='hero'>
         {/* Flex Container */}
         <div className='container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row'>
            {/* Image */}
            <div className='  sm:block hidden'>
               <img
                  src={photos}
                  alt=''
               />
            </div>
            {/* Right Item */}
            <div className='flex flex-col mb-32 space-y-1 2 md:w-1/2'>
               <h1 className='text-sky-900   max-w-md text-4xl font-bold text-center md:text-5xl md:text-left'>
                  Photos
               </h1>
               <div className='min-h-screen md:p-8'>
                  <div className='container mx-auto bg-slate-50 bg-opacity-60 p-6 rounded-lg shadow-lg'>
                     <p className='text-gray-700 mb-4'>
                        O Photos é um organizador de fotos de produtos
                        desenvolvido para a empresa Klin Produtos Infantis LTDA.
                        Este projeto foi criado com o objetivo de facilitar a
                        gestão visual do catálogo de produtos da empresa,
                        proporcionando uma maneira eficiente de armazenar,
                        organizar e acessar as imagens dos produtos.
                     </p>
                     <p className='text-gray-700 mb-4'>
                        Com o Photos, a Klin Produtos Infantis LTDA pode
                        facilmente fazer upload de fotos, categorizá-las e
                        atribuir descrições detalhadas a cada imagem. A
                        interface amigável e intuitiva do Photos permite que os
                        usuários encontrem rapidamente as fotos necessárias,
                        seja por categoria, data ou descrição.
                     </p>
                     <p className='text-gray-700 mb-4'>
                        Este organizador de fotos não só melhora a eficiência da
                        gestão do catálogo de produtos, mas também garante que
                        todas as imagens estejam armazenadas de forma segura e
                        acessível. O Photos é uma ferramenta essencial para a
                        Klin Produtos Infantis LTDA, ajudando a manter um
                        controle visual preciso e atualizado dos produtos.
                     </p>
                     <p className='text-gray-700'>
                        Em resumo, o Photos é uma solução prática e eficiente
                        para a organização de fotos de produtos, proporcionando
                        à Klin Produtos Infantis LTDA uma maneira fácil e segura
                        de gerenciar seu catálogo visual.
                     </p>
                     <div className='flex justify-center items-center pt-10 md:pt-4'>
                        <Link
                           to='/agendar-avaliacao'
                           className='p-3 px-6 pt-2 shadow-sm shadow-slate-400  text-white bg-orange-400 rounded-full baseline hover:bg-orange-300  '
                        >
                           Solicite uma avaliação
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default ApresentacaoPhotos;
