import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Features from '../components/Features';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';

const Home = () => {
   return (
      <div>
         <Navbar />
         <Hero />
         <Features />
         <div className='mt-40'>
            <CallToAction />
         </div>

         <Footer />
      </div>
   );
};

export default Home;
