import React from 'react';
import PrivacyPolicy from '../components/Politica-privacidade';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PoliticaDePrivacidade = () => {
   return (
      <div>
         <Navbar />
         <PrivacyPolicy />
         <Footer />
      </div>
   );
};

export default PoliticaDePrivacidade;
