import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LeadForm from '../pages/agendar-avaliacao';
import Home from '../pages/home';
import PoliticaEPrivacidade from '../pages/politica-de-privacidade';
import SobreNos from '../pages/sobre-nos';
import Projetos from '../pages/projetos';

const Rotas = () => {
   return (
      <BrowserRouter>
         <Routes>
            <Route
               path='/'
               element={<Home />}
            />

            {/* Rota de fallback para redirecionar para "/" em caso de rota não encontrada */}
            <Route
               path='*'
               element={<Navigate to='/' />}
            />
            <Route
               path='/agendar-avaliacao'
               element={<LeadForm />}
            />
            <Route
               path='/politica-de-privacidade'
               element={<PoliticaEPrivacidade />}
            />
            <Route
               path='/sobre-nos'
               element={<SobreNos />}
            />
            <Route
               path='/projetos'
               element={<Projetos />}
            />
         </Routes>
      </BrowserRouter>
   );
};

export default Rotas;
