import React from 'react';
import imagemPolitica from '../assets/images/explicacao.svg';

const PrivacyPolicy = () => {
   return (
      <div className='min-h-screen  md:p-8'>
         <div className='  opacity-25 flex item justify-center z-[-30] '>
            <div className='sm:block pt-72 pl-64 absolute hidden w-full 2xl:hidden'>
               <img
                  src={imagemPolitica}
                  alt=''
               />
            </div>
         </div>
         <div className='container mx-auto bg-slate-50 bg-opacity-60 p-6 rounded-lg shadow-lg'>
            <h1 className='text-3xl font-bold text-sky-900 mb-6'>
               Política de Privacidade
            </h1>

            <section className='mb-6'>
               <h2 className='text-2xl font-bold text-sky-900 mb-3'>
                  Introdução
               </h2>
               <p className='text-gray-700'>
                  Bem-vindo à WF Software! Temos o compromisso de proteger sua
                  privacidade e garantir que seus dados pessoais sejam tratados
                  de forma segura e responsável. Esta Política de Privacidade
                  descreve como coletamos, usamos, divulgamos e protegemos suas
                  informações.
               </p>
            </section>

            <section className='mb-6'>
               <h2 className='text-2xl font-bold text-sky-900 mb-3'>
                  Coleta de Informações
               </h2>
               <p className='text-gray-700 mb-3'>
                  Coletamos informações pessoais e empresariais que você nos
                  fornece diretamente, como:
               </p>
               <ul className='list-disc list-inside text-gray-700 mb-3 pl-5'>
                  <li>Nome completo.</li>
                  <li>Endereço de e-mail.</li>
                  <li>Número de telefone.</li>
                  <li>Nome da empresa.</li>
                  <li>Website da empresa.</li>
                  <li>Informações sobre o projeto.</li>
               </ul>
               <p className='text-gray-700'>
                  Além disso, podemos coletar informações automaticamente
                  através do uso de cookies e outras tecnologias de
                  rastreamento, incluindo, mas não se limitando a:
               </p>
               <ul className='list-disc list-inside text-gray-700 pl-5'>
                  <li>Endereço IP.</li>
                  <li>Tipo de navegador.</li>
                  <li>Páginas visitadas.</li>
                  <li>Tempo de permanência em nossas páginas.</li>
               </ul>
            </section>

            <section className='mb-6'>
               <h2 className='text-2xl font-bold text-sky-900 mb-3'>
                  Uso das Informações
               </h2>
               <p className='text-gray-700'>
                  As informações coletadas são usadas para os seguintes
                  propósitos:
               </p>
               <ul className='list-disc list-inside text-gray-700 pl-5'>
                  <li>Fornecer e melhorar nossos serviços.</li>
                  <li>Personalizar sua experiência.</li>
                  <li>Processar transações.</li>
                  <li>
                     Enviar atualizações, newsletters e informações
                     promocionais.
                  </li>
                  <li>
                     Responder às suas perguntas e solicitações de suporte.
                  </li>
                  <li>Analisar o tráfego e o uso do site.</li>
               </ul>
            </section>

            <section className='mb-6'>
               <h2 className='text-2xl font-bold text-sky-900 mb-3'>
                  Compartilhamento de Informações
               </h2>
               <p className='text-gray-700'>
                  Não vendemos, alugamos ou compartilhamos suas informações
                  pessoais com terceiros, exceto nas seguintes circunstâncias:
               </p>
               <ul className='list-disc list-inside text-gray-700 pl-5'>
                  <li>
                     Quando exigido por lei ou para proteger nossos direitos
                     legais.
                  </li>
                  <li>
                     Em caso de fusão, aquisição ou venda de todos ou parte de
                     nossos ativos.
                  </li>
               </ul>
            </section>

            <section className='mb-6'>
               <h2 className='text-2xl font-bold text-sky-900 mb-3'>
                  Segurança das Informações
               </h2>
               <p className='text-gray-700'>
                  Implementamos medidas de segurança técnicas e organizacionais
                  para proteger suas informações pessoais contra acesso não
                  autorizado, perda, alteração ou destruição. No entanto, nenhum
                  sistema de segurança é impenetrável, e não podemos garantir a
                  segurança absoluta de suas informações.
               </p>
            </section>

            <section className='mb-6'>
               <h2 className='text-2xl font-bold text-sky-900 mb-3'>
                  Seus Direitos
               </h2>
               <p className='text-gray-700'>Você tem o direito de:</p>
               <ul className='list-disc list-inside text-gray-700 pl-5'>
                  <li>Acessar suas informações pessoais</li>
                  <li>Corrigir ou atualizar suas informações pessoais</li>
                  <li>Solicitar a exclusão de suas informações pessoais</li>
                  <li>
                     Restringir ou objetar ao processamento de suas informações
                     pessoais
                  </li>
                  <li>Portabilidade de dados</li>
               </ul>
               <p className='text-gray-700'>
                  Para exercer qualquer um desses direitos, entre em contato
                  conosco pelo e-mail{' '}
                  <a
                     href='mailto:contato@wfsoftware.com.br'
                     className='text-blue-600 underline'
                  >
                     contato@wfsoftware.com.br
                  </a>
                  .
               </p>
            </section>

            <section className='mb-6'>
               <h2 className='text-2xl font-bold text-sky-900 mb-3'>
                  Alterações na Política de Privacidade
               </h2>
               <p className='text-gray-700'>
                  Podemos atualizar esta Política de Privacidade periodicamente
                  para refletir mudanças em nossas práticas ou em leis
                  aplicáveis. Publicaremos qualquer alteração nesta página e, se
                  as alterações forem significativas, forneceremos um aviso mais
                  destacado.
               </p>
            </section>

            <section>
               <h2 className='text-2xl font-bold text-sky-900 mb-3'>Contato</h2>
               <p className='text-gray-700 mb-3'>
                  Se você tiver dúvidas ou preocupações sobre esta Política de
                  Privacidade, entre em contato conosco:
               </p>
               <address className='text-gray-700 not-italic'>
                  WF Software
                  <br />
                  E-mail:
                  <a
                     href='mailto:contato@wfsoftware.com.br'
                     className='text-blue-600 underline ml-3'
                  >
                     contato@wfsoftware.com.br
                  </a>
                  <br />
                  Telefone: (18) 99688-3949
               </address>
            </section>
         </div>
      </div>
   );
};

export default PrivacyPolicy;
