import { Link } from 'react-router-dom';

import exportacao from '../assets/images/exportacao.svg';

const ApresentacaoExportacao = () => {
   return (
      <section id='hero'>
         {/* Flex Container */}
         <div className='container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row'>
            {/* Left Item */}
            <div className='flex flex-col mb-8 space-y-1 2 md:w-1/2'>
               <h1 className='text-sky-900   max-w-md text-4xl font-bold text-center md:text-5xl md:text-left'>
                  Projeto Exportação
               </h1>
               <div className='min-h-screen md:p-8'>
                  <div className='container mx-auto bg-slate-50 bg-opacity-60 p-6 rounded-lg shadow-lg'>
                     <p className='text-gray-700 mb-4'>
                        O Projeto Exportação é uma ferramenta desenvolvida para
                        a Klin Produtos Infantis LTDA com o propósito de
                        simplificar e automatizar o processo de criação de
                        faturas de exportação e documentação relacionada. Esta
                        solução está integrada com o ERP Safetech, permitindo um
                        fluxo de trabalho contínuo e eficiente.
                     </p>
                     <p className='text-gray-700 mb-4'>
                        Com o Projeto Exportação, a Klin Produtos Infantis LTDA
                        pode gerar faturas de exportação detalhadas e precisas,
                        bem como toda a documentação necessária para o envio
                        internacional de produtos. A integração com o ERP
                        Safetech garante que todas as informações relevantes
                        sejam automaticamente incluídas nas faturas e
                        documentos, reduzindo erros e economizando tempo.
                     </p>
                     <p className='text-gray-700 mb-4'>
                        Este projeto não só facilita a conformidade com os
                        requisitos legais e regulamentares internacionais, mas
                        também melhora a eficiência do processo de exportação,
                        permitindo à empresa focar em seu crescimento e expansão
                        global.
                     </p>
                     <p className='text-gray-700'>
                        Em resumo, o Projeto Exportação é uma ferramenta
                        essencial para a Klin Produtos Infantis LTDA, oferecendo
                        uma solução integrada e eficiente para a gestão de
                        faturas e documentação de exportação.
                     </p>
                     <div className='flex justify-center items-center pt-10 md:pt-4'>
                        <Link
                           to='/agendar-avaliacao'
                           className='p-3 px-6 pt-2 shadow-sm shadow-slate-400  text-white bg-orange-400 rounded-full baseline hover:bg-orange-300  '
                        >
                           Solicite uma avaliação
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
            {/* Image */}
            <div className='  sm:block hidden'>
               <img
                  src={exportacao}
                  alt=''
               />
            </div>
         </div>
      </section>
   );
};

export default ApresentacaoExportacao;
