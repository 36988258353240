import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import AboutUs from '../components/Sobre-nos';

const SobreNos = () => {
   return (
      <div>
         <Navbar />
         <AboutUs />
         <Footer />
      </div>
   );
};

export default SobreNos;
