import { Link } from 'react-router-dom';
import { PiCircuitryDuotone } from 'react-icons/pi';
import {
   FaGithubSquare,
   FaInstagramSquare,
   FaLinkedin,
   FaWhatsappSquare,
} from 'react-icons/fa';

const Footer = () => {
   return (
      <div className='bg-sky-900'>
         {/* Flex Container */}
         <div className='container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0'>
            {/* Logo and social links container */}
            <div className='flex flex-col-reverse items-center justify-between space-y-12 md:flex-col md:space-y-0 md:items-start'>
               <div className='mx-auto my-6 text-center text-white md:hidden'>
                  Copyright © 2024, Todos Direitos Reservados
               </div>
               {/* Logo */}
               <div className='flex items-center justify-center'>
                  <PiCircuitryDuotone className='text-5xl text-orange-600' />
                  <h1 className='text-3xl font-bold text-white'>WF Software</h1>
               </div>
               {/* Social Links Container */}
               <div className='flex justify-center space-x-4 ml-4 md:ml-0'>
                  {/* Instagram 1 */}
                  <a href='https://www.instagram.com/wfsoftware'>
                     <FaInstagramSquare className='text-5xl text-white' />
                  </a>
                  {/* Linkedin */}
                  <a href='https://www.linkedin.com/in/wellinton-faustino-ba6919207'>
                     <FaLinkedin className='text-5xl text-white' />
                  </a>
                  {/* Github */}
                  <a href='https://github.com/wellintonfaustino'>
                     <FaGithubSquare className='text-5xl text-white' />
                  </a>
                  {/* Whatsapp */}
                  <a href='https://wa.me/5518996883949'>
                     <FaWhatsappSquare className='text-5xl text-white' />
                  </a>
               </div>
            </div>
            {/* List Container */}
            <div className='flex justify-around space-x-32'>
               <div className='flex flex-col space-y-3 text-white'>
                  <Link
                     to='/'
                     className='hover:text-brightRed'
                  >
                     Home
                  </Link>
                  <Link
                     to='/projetos'
                     className='hover:text-primary-content'
                  >
                     Soluções
                  </Link>
                  <Link
                     to='/sobre-nos'
                     className='hover:text-primary-content'
                  >
                     Sobre nós
                  </Link>
               </div>
               <div className='flex flex-col space-y-3 text-white'>
                  <Link
                     to='/agendar-avaliacao'
                     className='hover:text-brightRed'
                  >
                     Orçamentos
                  </Link>
                  <Link
                     to='/politica-de-privacidade'
                     className='hover:text-orange-200'
                  >
                     Política de privacidade
                  </Link>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Footer;
