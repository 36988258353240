import { Link } from 'react-router-dom';

import illustrationIntro from '../assets/images/home.svg';

const Hero = () => {
  return (
    <section id='hero'>
      {/* Flex Container */}
      <div className='container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row'>
        {/* Left Item */}
        <div className='flex flex-col mb-32 space-y-1 2 md:w-1/2'>
          <h1 className='text-sky-900   max-w-md text-4xl font-bold text-center md:text-5xl md:text-left'>
            Desenvolvendo soluções empresariais integradas ao seu ERP
          </h1>
          <p className='max-w-sm text-center text-secondary md:text-left pt-5 '>
            Solicite soluções de software e sistemas para seu negócio. Montamos do zero e de ponta a ponta, integramos com os maiores ERP's do mercado. 
          </p>
          <div className='flex justify-center md:justify-start pt-10 md:pt-4'>
            <Link
              to='/agendar-avaliacao'
              className='p-3 px-6 pt-2 shadow-sm shadow-slate-400  text-white bg-orange-400 rounded-full baseline hover:bg-orange-300  '
            >
              Solicite uma avaliação
            </Link>
          </div>
        </div>
        {/* Image */}
        <div className='  sm:block hidden'>
          <img src={illustrationIntro} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Hero;
