import React from 'react';

const Features = () => {
  return (
    <section id='features'>
      {/* Flex Container */}
      <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row'>
        {/* What's Different */}
        <div className='flex flex-col space-y-12 md:w-1/2'>
          <h2 className='text-sky-900 max-w-md text-4xl font-bold text-center md:text-left'>
            Porquê, somos diferentes...
          </h2>
          <p className='max-w-sm text-center text-darkGrayishBlue md:text-left'>
            Todo nosso projeto foi pensando para atender as deficiências e necessidades dos nossos clientes, sendo como uma solução satélite integrada ou uma aplicação principal.
          </p>
        </div>

        {/* Numbered List */}
        <div className='flex flex-col space-y-8 md:w-1/2'>
          {/* List Item 1 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-orange-400'>
                  01
                </div>
                <h3 className='text-sky-900 font-bold md:mb-4 md:hidden'>
                  Modularização de projeto
                </h3>
              </div>
            </div>

            <div>
              <h3 className='text-sky-900 hidden mb-4 text-lg font-bold md:block'>
              Modularização de projeto
              </h3>
              <p className='text-darkGrayishBlue'>
                Pague apenas o que precisar e o que for necessário. 
                <br/>
                Toda a solução se encontra em um lugar, solicite uma avaliação gratuita.
                <br/>
                Em nossa aba de projetos, veja um overview de todas as soluções disponíveis. 
                <br/>
                <div className='link'> Clique aqui para conhecer mais.</div>
                 
              </p>
            </div>
          </div>

          {/* List Item 2 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-orange-400'>
                  02
                </div>
                <h3 className='text-sky-900 font-bold md:mb-4 md:hidden'>
                  Preços acessíveis
                </h3>
              </div>
            </div>

            <div>
              <h3 className='text-sky-900 hidden mb-4 text-lg font-bold md:block'>
              Preços acessíveis
              </h3>
              <p className='text-darkGrayishBlue'>
                Temos como ideias que todos nosso clientes necessitam e precisam de apoio para crescer, assim como nós.
                <br/>
                Contudo, sempre atualizamos as soluções já existentes e desenvolvemos novas, prezando sempre pela qualidade e o menor custo do mercado.
              </p>
            </div>
          </div>

          {/* List Item 3 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-orange-400'>
                  03
                </div>
                <h3 className='text-sky-900 font-bold md:mb-4 md:hidden'>
                Simples, rápido, fácil... e automático
                </h3>
              </div>
            </div>

            <div>
              <h3 className='text-sky-900 hidden mb-4 text-lg font-bold md:block'>
                Simples, rápido, fácil... e automático
              </h3>
              <p className='text-darkGrayishBlue'>
                Buscamos sempre atender as necessidades dos nossos clientes de forma coesa e transparente. 
                <br/>
                Traga automação para sua empresa de forma segura e eficiente.
                <br/>
                <div className='font-bold text-red-800'>
                "Chega de input desnecessários e repetitivos"
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
