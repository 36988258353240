import Rotas from './routes/routes.js';

function App() {
   return (
      <div>
         <Rotas />
      </div>
   );
}

export default App;
