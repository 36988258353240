import React from 'react';

const AboutUs = () => {
  return (
    <div className='min-h-screen md:p-8'>
      <div className='container mx-auto bg-slate-50 bg-opacity-60 p-6 rounded-lg shadow-lg'>
        <h1 className='text-3xl font-bold text-sky-900 mb-6'>Sobre Nós</h1>

        <section className='mb-6'>
          <h2 className='text-2xl font-bold text-sky-900 mb-3'>Introdução</h2>
          <p className='text-gray-700'>
            A WF Software é uma empresa dedicada ao desenvolvimento de soluções de software inovadoras, fundada por Wellinton Faustino, um estudante de Engenharia de Software. Nossa missão é transformar ideias inovadoras em soluções digitais que impulsionam o sucesso de nossos clientes.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-bold text-sky-900 mb-3'>Nossa História</h2>
          <p className='text-gray-700 mb-3'>
            A idealização da WF começou em setembro de 2023, quando Wellinton finalizou o desenvolvimento do <strong>Klin Reports</strong>. Este projeto foi um gerador de relatórios criado para a empresa Klin Produtos Infantis LTDA. Nos primeiros 8 meses, o Klin Reports gerou mais de 100 mil relatórios, ajudando a empresa a reduzir custos significativamente e aumentar a agilidade em seus processos.
          </p>

          <p className='text-gray-700 mb-3'>
            Após o sucesso do Klin Reports, Wellinton desenvolveu o <strong>Photos</strong>, um organizador de fotos dos produtos da Klin Produtos Infantis LTDA. Este projeto facilitou a gestão visual do catálogo de produtos da empresa, permitindo uma organização eficiente e acesso rápido às imagens necessárias.
          </p>

          <p className='text-gray-700'>
            O próximo passo na trajetória da WF Software foi o desenvolvimento do <strong>Projeto Exportação</strong>. Esta ferramenta foi projetada para criar faturas de exportação e documentações necessárias, integrando-se perfeitamente com o ERP Safetech. O Projeto Exportação simplificou o processo de exportação para a Klin Produtos Infantis LTDA, garantindo conformidade e eficiência nas operações internacionais.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-bold text-sky-900 mb-3'>Missão e Valores</h2>
          <p className='text-gray-700 mb-3'>
            Na WF Software, nossa missão é desenvolver soluções tecnológicas que não apenas atendam, mas superem as expectativas dos nossos clientes. Acreditamos na inovação contínua, na busca pela excelência e na importância da transparência e integridade em todas as nossas interações.
          </p>
          <p className='text-gray-700 mb-3'>
            Valorizamos a parceria e a colaboração, trabalhando em estreita cooperação com nossos clientes para entender suas necessidades específicas e fornecer soluções personalizadas que impulsionem o seu sucesso. A satisfação do cliente está no centro de tudo o que fazemos.
          </p>
          <p className='text-gray-700'>
            Estamos comprometidos em oferecer produtos e serviços de alta qualidade que promovam o crescimento sustentável e bem-sucedido de nossos clientes.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
